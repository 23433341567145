import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Karta from "../components/Karta";
import BanerPageLayout from "../layout/BanerPageLayout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import KartaRichText from "../components/KartaRichText";
import SEO from "../components/SEO";

const Zalecenia = () => {
  const {
    allContentfulStronaZalecenia: {
      nodes: [nodes],
    },
  } = useStaticQuery(graphql`
    query {
      allContentfulStronaZalecenia {
        nodes {
          baner {
            gatsbyImageData
            description
            contentful_id
            __typename
          }
          seoOpisKarty
          seoTytulKarty
          tresc {
            raw
          }
          baner {
            filename
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <SEO {...nodes} />

      <BanerPageLayout baner={nodes.baner}>
        <KartaRichText content={nodes.tresc} />
      </BanerPageLayout>
    </React.Fragment>
  );
};

export default Zalecenia;
