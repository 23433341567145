import * as React from "react";
import Karta from "./Karta";
import getRichText from "../utils/getRichText";
import { FontSizeHeader, Label } from "../styles/shared";
import styled from "styled-components";

const Tytul = styled.h1`
  ${Label}
  ${FontSizeHeader}
  align-self: center;
  padding-bottom: 1em;
`;

const RichText: React.FC<{
  content: { raw: string; references: any[] };
  tytul?: string;
}> = ({ content, tytul }) => {
  return (
    <Karta>
      {tytul && <Tytul>{tytul}</Tytul>}
      {getRichText({ content })}
    </Karta>
  );
};

export default RichText;
